<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="Billing"
      title="Billing "
    >
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-primary ',
            path: '/dashboard',
          }"
        />

        <!-- <sidebar-item
          :link="{
            name: 'User',
            icon: 'ni ni-circle-08 text-pink',
            path: '/user/view_user',
          }"
        /> -->

        <sidebar-item
          :link="{
            name: 'Members List',
            icon: 'ni ni-single-02 text-yellow',
            path: '/member/viewmember',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Seat Management',
            icon: 'ni ni-map-big',
            path: '/seatManagement/create_seatmanagement',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Reports',
            icon: 'ni ni-single-copy-04 text-orange',
          }"
        >
          <sidebar-item
            :link="{
              name: '30,60,90 Report',
              path: '/report/month_report',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Income Report',
              path: '/report/income_report',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Charge Report',
              path: '/report/charge_report',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Member Charge Report',
              path: '/report/member_charge_report',
            }"
          />

          <sidebar-item
            :link="{
              name: 'Member Income Report',
              path: '/report/member_income_report',
            }"
          />
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Accounts',
            icon: 'ni ni-circle-08 text-blue',
            path: '/account/view_account',
          }"
        />

        <sidebar-item
          :link="{
            name: 'Aliyos',
            icon: 'fa fa-gavel',
            path: '/auction/view_auction',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Bulk Charge',
            icon: 'ni ni-box-2 text-black',
            path: '/bulkcharge/view_bulkcharge',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Payment',
            icon: 'ni ni-credit-card text-pink',
            path: '/payment/view_payment',
          }"
        />

        <sidebar-item
          :link="{
            name: 'Reciept',
            icon: 'ni ni-single-copy-04',
            path: '/reciept/view_reciept',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Calendar',
            icon: 'ni ni-calendar-grid-58',
            path: '/calender/view_calender',
          }"
        />

        <sidebar-item
          :link="{
            name: 'Setup',
            icon: 'ni ni-money-coins text-orange',
          }"
        >
          <sidebar-item
            :link="{
              name: 'Manage Rooms',
              icon: 'ni ni-bullet-list-67 text-red',
              path: '/manageroom/view_manageroom',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Member Groups',
              icon: 'ni ni-single-02 text-yellow',
              path: '/memberGroup/view_member_group',
            }"
          />

          <sidebar-item
            :link="{
              name: 'Aliyos Template',
              icon: 'fa fa-gavel',
              path: '/auction/view_auction_template',
            }"
          />
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Settings',
            icon: 'ni ni-settings text-orange',
          }"
        >
          <sidebar-item
            :link="{ name: 'Charges', path: '/manageCharges/view_Charges' }"
          />
          <sidebar-item
            :link="{
              name: 'Memberships',
              path: '/manageCharges/view_Membership',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Subscriptions',
              path: '/manageCharges/view_subscription',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Item Auction',
              path: '/manageCharges/view_Itemauction',
            }"
          />

          <sidebar-item
            :link="{
              name: 'Email-and-Print',
              icon: 'ni ni-settings text-orange',
            }"
          >
            <sidebar-item
              :link="{
                name: 'Email Template',
                path: '/emailtemplate/view_emailtemplate',
              }"
            />
            <sidebar-item
              :link="{
                name: 'Print Reciept',
                path: '/setting/print_reciept',
              }"
            />
            <sidebar-item
              :link="{
                name: 'Statement',
                path: '/setting/statement',
              }"
            />
          </sidebar-item>
        </sidebar-item>

        <!-- <sidebar-item
          :link="{
            name: 'Info',
            icon: 'fa fa-info-circle',
            path: '/info/view_info',
          }"
        /> -->
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      visible: true,
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss"></style>
